import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Center, Container, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Form, FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { clientConfig } from '~/core/config/config';
import { type ContactUsForm, contactUsFormSchema } from '~/server/services/crm/types';
import { useLocale } from '~/ui/hooks/locale.hook';
import { useColorBySchema } from '~/ui/hooks/mantine-color.hook';
import { FormEmailInput } from '../Form/Email';
import { FormInput } from '../Form/Input/Input';
import { FormPhoneInput } from '../Form/PhoneInput/PhoneInput';
import { SubmitButton } from '../Form/Submit';
import { FormTextarea } from '../Form/Textarea';
import { useGoogleAds } from '../Integrations/GoogleAds/google-ads.hook';

const xIcon = <IconX size={20} />;
const checkIcon = <IconCheck size={20} />;

export const GetInTouch: React.FC = () => {
  const { t } = useTranslation('homePage');
  const bg = useColorBySchema('violet.0', 'dark');
  const { fireGoogleConversion } = useGoogleAds();
  const { locale } = useLocale();

  const formMethods = useForm<ContactUsForm>({
    mode: 'onChange',
    defaultValues: {
      mainContact: '',
      company: '',
      phone: '',
      email: '',
      description: '',
      language: locale,
    },
    resolver: zodResolver(contactUsFormSchema),
  });

  const { mutateAsync: contactUs, isPending } = useMutation({
    mutationFn: async (formData: ContactUsForm) => {
      const res = await fetch(`${clientConfig.origin}/api/public/contact-us`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      if (res.status !== 200) {
        throw new Error('Failed to send message');
      }
    },
    onError: () => {
      notifications.show({
        icon: xIcon,
        title: t('Something went wrong'),
        message: t('Failed to send message, please try again later'),
        color: 'red',
      });
    },
    onSuccess: () => {
      notifications.show({
        icon: checkIcon,
        title: t('All good'),
        message: t('We got your message and will get back to you soon'),
        color: 'green',
      });
      formMethods.reset();

      fireGoogleConversion('lead');
    },
  });

  const onSubmit: SubmitHandler<ContactUsForm> = useCallback(
    async (data) => {
      await contactUs({ ...data, language: locale });
    },
    [contactUs, locale],
  );

  return (
    <Box w="100%" bg={bg}>
      <Container pt={'min(8vw, 80px)'} pb={'min(8vw, 200px)'} size={1000}>
        <Stack gap={'xl'}>
          <Title order={2} ta="center" mt="sm">
            {t('Get in touch')}
          </Title>

          <Text
            fw={400}
            style={{
              fontSize: 'max(min(1.3vw,1.2rem), 1rem)',
              textAlign: 'center',
            }}
          >
            {t(
              `Still not convinced to try our app? No worries! Reach out to us, and one of our team members will get in touch with you shortly. We're here to answer any questions and provide you with all the information you need`,
            )}
          </Text>
          <FormProvider {...formMethods}>
            <Form id="contact-form" onSubmit={() => formMethods.handleSubmit(onSubmit)()}>
              <Stack gap={'xl'}>
                <SimpleGrid cols={{ base: 1, sm: 2 }} mt="xl">
                  <FormInput
                    size="md"
                    name="mainContact"
                    label={t('Name')}
                    placeholder={t('Your Name')}
                  />
                  <FormInput
                    size="md"
                    name="company"
                    label={t('Company')}
                    placeholder={t('Your Company')}
                  />
                  <FormEmailInput
                    size="md"
                    name="email"
                    label={t('Email')}
                    placeholder={t('Your Email')}
                  />
                  <FormPhoneInput
                    size="md"
                    name="phone"
                    label={t('Phone')}
                    placeholder={t('Your Phone')}
                  />
                </SimpleGrid>
                <FormTextarea
                  size="md"
                  name="description"
                  label={t('Message')}
                  placeholder={t('Your Message')}
                  minRows={4}
                  maxRows={5}
                />
                <Center>
                  <SubmitButton radius={'xl'} size="md" loading={isPending}>
                    {t('Hit Us Up')}
                  </SubmitButton>
                </Center>
              </Stack>
            </Form>
          </FormProvider>
        </Stack>
      </Container>
    </Box>
  );
};
