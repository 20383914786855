import { env } from '~/env.mjs';
import { localConfig } from './local';
import { prodConfig } from './prod';
import { ClientConfigInput, clientConfigSchema } from './type';

const config = (): ClientConfigInput => {
  if (env.NEXT_PUBLIC_APP_ENV === 'production') {
    return prodConfig;
  }
  return localConfig;
};

export const clientConfig = clientConfigSchema.parse(config());
