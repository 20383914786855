import { useCardsNavigationHistory } from '~/Components/Cards/CardsTable/cards-table-navigation.hook';

const updateHistory = (slug: string) => {
  useCardsNavigationHistory.setState((prev) => ({
    ...prev,
    url: window.location.href.replace(window.location.origin, ''),
    slug,
  }));
};

export const cardsNavigationUtils = {
  updateHistory,
};
