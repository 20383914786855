import { z } from 'zod';

/**
 * Specify your server-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars.
 */
const server = z.object({
  DATABASE_URL: z.string().url().optional(),
  ALLOW_DB_MIGRATIONS: z.enum(['true', 'false']).default('false'),
  ALLOW_DB_SEED: z.enum(['true', 'false']).default('false'),
  DATABASE_URL_PROD: z.string().url().optional(),
  NODE_ENV: z.enum(['development', 'test', 'production']).default('test'),
  APP_ENV: z.enum(['development', 'test', 'production']).default('test'),
  OAUTH2_STATE_SECRET: z.string().min(1),
  CLERK_SECRET_KEY: z.string().min(1),
  CLOUD_AWS_S3_BUCKET_NAME: z.string(),
  CLOUD_AWS_ACCESS_KEY_ID: z.string(),
  CLOUD_AWS_SECRET_ACCESS_KEY: z.string(),
  CLOUD_AWS_REGION: z.string(),
  CRM_API_TOKEN: z.string().optional(),
  QSTASH_URL: z.string().url(),
  QSTASH_TOKEN: z.string().min(1),
  QSTASH_CURRENT_SIGNING_KEY: z.string().min(1),
  QSTASH_NEXT_SIGNING_KEY: z.string().min(1),
  QSTASH_PUBLISH_URL: z.string().url().optional(),
  PUBLIC_URL: z.string().optional(), // This is only used for the server to know where the app is hosted
  RESEND_API_KEY: z.string().optional(),
  OPENAI_API_KEY: z.string().optional(),
  TWILIO_ACCOUNT_SID: z.string().optional(),
  TWILIO_AUTH_TOKEN: z.string().optional(),
  INFORU_API_KEY: z.string().optional(),
  LEGACY_CRM_API_USERNAME: z.string().optional(),
  LEGACY_CRM_API_PASSWORD: z.string().optional(),

  PUBLISH_AUDIT_TO: z.enum(['messagingQueue', 'db']).optional(),
  // Certificate
  CERTIFICATE_PRIVATE_KEY: z.string().optional(),
  CERTIFICATE_PUBLIC_KEY: z.string().optional(),
  CERTIFICATE_PASSPHRASE: z.string().optional(),
  // Ably
  ABLY_SERVER_API_KEY: z.string().optional(),
  // PayPal
  PAYPAL_CLIENT_SECRET: z.string().optional(),
  // Google
  GOOGLE_CLIENT_ID: z.string(),
  GOOGLE_CLIENT_SECRET: z.string(),
  GOOGLE_API_KEY: z.string(),

  // Add `.min(1) on ID and SECRET if you want to make sure they're not empty
});

/**
 * Specify your client-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars. To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
  NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string().min(1),
  NEXT_PUBLIC_ENV: z.enum(['development', 'test', 'production']).default('test'),
  NEXT_PUBLIC_APP_ENV: z.enum(['development', 'test', 'production']).default('test'),
  NEXT_PUBLIC_POSTHOG_KEY: z.string().optional(),
  NEXT_PUBLIC_POSTHOG_HOST: z.string().optional(),
  NEXT_PUBLIC_BULK_REQUESTS: z.enum(['true', 'false']).default('true'),
  NEXT_PUBLIC_CLERK_APP_ID: z.string().optional(),
  NEXT_PUBLIC_PAYPAL_CLIENT_ID: z.string().optional(),
});

/**
 * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
 * middlewares) or client-side so we need to destruct manually.
 *
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  DATABASE_URL: process.env.DATABASE_URL,
  DATABASE_URL_PROD: process.env.DATABASE_URL_PROD,
  ALLOW_DB_MIGRATIONS: process.env.ALLOW_DB_MIGRATIONS,
  ALLOW_DB_SEED: process.env.ALLOW_DB_SEED,
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: process.env.APP_ENV ?? process.env.NODE_ENV,
  OAUTH2_STATE_SECRET: process.env.OAUTH2_STATE_SECRET,
  CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
  CLOUD_AWS_S3_BUCKET_NAME: process.env.CLOUD_AWS_S3_BUCKET_NAME,
  CLOUD_AWS_ACCESS_KEY_ID: process.env.CLOUD_AWS_ACCESS_KEY_ID,
  CLOUD_AWS_SECRET_ACCESS_KEY: process.env.CLOUD_AWS_SECRET_ACCESS_KEY,
  CLOUD_AWS_REGION: process.env.CLOUD_AWS_REGION,
  CRM_API_TOKEN: process.env.CRM_API_TOKEN,
  QSTASH_URL: process.env.QSTASH_URL,
  QSTASH_TOKEN: process.env.QSTASH_TOKEN,
  QSTASH_CURRENT_SIGNING_KEY: process.env.QSTASH_CURRENT_SIGNING_KEY,
  QSTASH_NEXT_SIGNING_KEY: process.env.QSTASH_NEXT_SIGNING_KEY,
  QSTASH_PUBLISH_URL: process.env.QSTASH_PUBLISH_URL,
  PUBLIC_URL: process.env.VERCEL_URL ?? process.env.PUBLIC_URL ?? 'http://localhost:3050',
  RESEND_API_KEY: process.env.RESEND_API_KEY,
  OPENAI_API_KEY: process.env.OPENAI_API_KEY,
  TWILIO_ACCOUNT_SID: process.env.TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN: process.env.TWILIO_AUTH_TOKEN,
  INFORU_API_KEY: process.env.INFORU_API_KEY,
  LEGACY_CRM_API_USERNAME: process.env.LEGACY_CRM_API_USERNAME,
  LEGACY_CRM_API_PASSWORD: process.env.LEGACY_CRM_API_PASSWORD,
  PUBLISH_AUDIT_TO: process.env.PUBLISH_AUDIT_TO,
  // Certificate
  CERTIFICATE_PRIVATE_KEY: process.env.CERTIFICATE_PRIVATE_KEY,
  CERTIFICATE_PUBLIC_KEY: process.env.CERTIFICATE_PUBLIC_KEY,
  CERTIFICATE_PASSPHRASE: process.env.CERTIFICATE_PASSPHRASE,

  // Ably
  ABLY_SERVER_API_KEY: process.env.ABLY_SERVER_API_KEY,

  // PayPal
  PAYPAL_CLIENT_SECRET: process.env.PAYPAL_CLIENT_SECRET,

  // Google
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
  GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,

  // PUBLIC
  NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
  NEXT_PUBLIC_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_APP_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  NEXT_PUBLIC_BULK_REQUESTS: process.env.NEXT_PUBLIC_BULK_REQUESTS,
  NEXT_PUBLIC_CLERK_APP_ID: process.env.NEXT_PUBLIC_CLERK_APP_ID,

  NEXT_PUBLIC_PAYPAL_CLIENT_ID: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
};

// Don't touch the part below
// --------------------------

const merged = server.merge(client).refine((data) => {
  // Make sure one of the two database URLs is set
  if (!data.DATABASE_URL && !data.DATABASE_URL_PROD) return false;
  // Make sure only one of the two database URLs is set
  if (data.DATABASE_URL && data.DATABASE_URL_PROD) return false;
  return true;
}, 'one of the two database URLs is required: DATABASE_URL or DATABASE_URL_PROD');

/** @typedef {z.input<typeof merged>} MergedInput */
/** @typedef {z.infer<typeof merged>} MergedOutput */
/** @typedef {z.SafeParseReturnType<MergedInput, MergedOutput>} MergedSafeParseReturn */

let env = /** @type {MergedOutput} */ (process.env);

if (!!process.env.SKIP_ENV_VALIDATION === false) {
  const isServer = typeof window === 'undefined';

  const parsed = /** @type {MergedSafeParseReturn} */ (
    isServer
      ? merged.safeParse(processEnv) // on server we can validate all env vars
      : client.safeParse(processEnv) // on client we can only validate the ones that are exposed
  );

  if (parsed.success === false) {
    console.error('❌ Invalid environment variables:', parsed.error.flatten().fieldErrors);
    console.error('❌ Invalid environment variables - general:', parsed.error.flatten().formErrors);
    console.log(processEnv);
    throw new Error('Invalid environment variables');
  }

  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== 'string') return undefined;
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isServer && !prop.startsWith('NEXT_PUBLIC_'))
        throw new Error(
          process.env.NODE_ENV === 'production'
            ? '❌ Attempted to access a server-side environment variable on the client'
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`,
        );
      return target[/** @type {keyof typeof target} */ (prop)];
    },
  });
}

export { env };
