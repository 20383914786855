export const cardTypeComponent = {
  'Card type created': 'סוג כרטיסיות נוצר',
  'Card type created successfully': 'סוג כרטיסיות נוצר בהצלחה',
  'Card type updated': 'סוג כרטיסיות עודכן',
  'Card type updated successfully': 'סוג כרטיסיות עודכן בהצלחה',
  'Create card type': 'צור סוג כרטיס',
  'Create new layout': 'צור תצוגה חדשה',
  'Are you sure that you want to delete this layout?': 'האם אתה בטוח שברצונך למחוק תצוגה זו',
  'Card type': 'סוג כרטיס',
  New: 'צור',
  'General information': 'מידע כללי',
  Fields: 'שדות',
  'Cards relations': 'קישורי כרטיסים',
  'Relation delete': 'מחיקת קישור',
  'Are you sure you want to delete this relation view?':
    'האם אתה בטוח שברצונך למחוק את תצוגת הקישור הזו?',
  Add: 'הוסף',
  'Field name': 'שם שדה',
  'Field type': 'סוג שדה',
  '+ Create "{{query}}"': `+ צור "{{query}}"`,
  Options: 'ערכים',
  Description: 'תיאור',
  Displayed: 'מוצג',
  Required: 'חובה',
  'Live Example': 'דוגמא',
  'Field Form Preview': 'תצוגת שדה בטופס',
  'Field Display': 'תצוגת שדה',
  'No value': 'אין ערך',
  'Default value': 'ערך ברירת מחדל',
  'Default values': 'ערכי ברירת מחדל',
  'System API name': 'שם API מערכת',
  'Remove field': 'הסר שדה',
  'Are you sure that you want to remove "{{label}}" field?': `האם אתה בטוח שברצונך להסיר את השדה "{{label}}"?`,
  'No fields': 'אין שדות',
  Name: 'שם',
  System: 'מערכת',
  Type: 'סוג',
  Values: 'ערכים',
  '{{count}} more...': `עוד {{count}}...`,
  Default: 'ברירת מחדל',
  Color: 'צבע',
  Order: 'סדר',
  'Sort order': 'מיקום ברשימה',
  'Card type visibility': 'נראות סוג הכרטיס',
  "When 'Hide from menu' is selected, this card type will not appear in the form menu or search results": `כאשר נבחר 'הסתר מהתפריט', סוג הכרטיס הזה לא יופיע בתפריט הטופס או בתוצאות החיפוש`,
  "Usualy this feature is used when this card type is used only for relations and shouldnֿ't be created directly": `בדרך כלל הגדרה זו משמשת כאשר סוג הכרטיס הזה משמש רק לקישורי כרטיסים ולא צריך ליצור אותו ישירות`,
  'Hide from menu': 'הסתר מהתפריט',
  'Hidden from menu and search results': 'מוסתר מהתפריט ומתוצאות החיפוש',
  'Card type identifier': 'מזהה סוג הכרטיס',
  Identifiers: 'מזהים',
  'Danger Zone': 'אזור מסוכן',
  'Delete card type': 'מחיקת סוג כרטיס',
  'Delete layout': 'מחיקת תצוגה',
  'Identifiers are used to determine whether two cards are duplicates':
    'מזהים משמשים כדי לקבוע אם שני כרטיסים הם כפולים',
  'If no identifiers are set, duplication detection is disabled, meaning the system will not check for or flag duplicate cards':
    'אם לא מוגדרים מזהים, מערכת הזיהוי לכפילויות לא תהיה פעילה, כלומר המערכת לא תבדוק ולא תסמן כרטיסים כפולים',
  'Duplications detection enable': 'מנגון זיהוי כפילויות פעיל',
  'More details': 'פרטים נוספים',
  'Add option': 'הוסף אפשרות',
  'New option': 'אפשרות חדשה',
  none: 'ללא',
  'no color': 'ללא צבע',
  'Pick color': 'בחר צבע',
  'Statuses and Progress': 'סטטוסים ותהליכים',
  Statuses: 'סטטוסים',
  Processes: 'תהליכים',
  Layouts: 'תצוגות',
  Layout: 'תצוגה',
  'Main Layout': 'תצוגה ראשית',
  'Layout created': 'תצוגה נוצרה',
  'Layout created successfully': 'תצוגה נוצרה בהצלחה',
  'Layout updated': 'תצוגה עודכנה',
  'Layout updated successfully': 'תצוגה עודכנה בהצלחה',
  Error: 'שגיאה',
  Title: 'כותרת',
  Save: 'שמור',
  Create: 'צור חדש',
  'Add layout': 'הוסף תצוגה',
  'New layout': 'תצוגה חדשה',
  Loading: 'טוען',
  'Created at': 'נוצר בתאריך',
  'Last update': 'עדכון אחרון',
  'API Name': 'שם API',
  Delete: 'מחיקה',
  'CardType deleted': 'סוג כרטיס נמחק',
  '{{cardTypeName}} deleted successfully': `סוג כרטיס "{{cardTypeName}}" נמחק בהצלחה`,
  'You have {{cardsCount}} records, You need to delete all records before': `יש לך {{cardsCount}} רשומות, עליך למחוק את כל הרשומות לפני`,
  'CardType Delete': 'מחיקת סוג כרטיס',
  'Are you sure that you want to remove "{{label}}" view?': `האם אתה בטוח שברצונך להסיר את התצוגה "{{label}}"?`,
  'This field is used to identify the field in API calls': 'שדה זה לזיהוי השדה בקריאות ה- API.',
  'Fields order': 'סדר השדות',
  'Fields display': 'שדות לתצוגה',
  'These fields define the card type. You can hide or change the fields position in the layout section.':
    'שדות אלו מגדירים את סוג הכרטיס. ניתן להסתיר או לשנות את מיקום השדות בסעיף התצוגה.',
  'Edit a views to link between cards. If a new link between cards is added, a view will be added automatically': `ערוך תצוגות לקישור בין כרטיסים. אם נוסף קישור חדש בין כרטיסים, תצוגה תתווסף באופן אוטומטי`,
  'Relation title in the card': 'כותרת הקישור בכרטיס',
  '{{cardType}} - Related fields': `{{cardType}} - שדות קשורים`,
  'View description': 'תיאור התצוגה',
  'Edit view': 'עריכת תצוגה',
  'View name': 'שם התצוגה',
  Slug: 'מזהה ייחודי',
  'Hide from card view': 'הסתר מתצוגת הכרטיס',
  'Visible in card view': 'נראה בתצוגת הכרטיס',
  Yes: 'כן',
  No: 'לא',
  Edit: 'עריכה',
  Actions: 'פעולות',
  Field: 'שדה',
  Label: 'תווית',
  Reset: 'איפוס',
  'Layout deleted': 'תצוגה נמחקה',
  'Layout deleted successfully': 'תצוגה נמחקה בהצלחה',

  // templates
  'Contacts and Leads': 'אנשי קשר ולידים',
  Contact: 'איש קשר',
  Contacts: 'אנשי קשר',
  Companies: 'חברות',
  Company: 'חברה',
  All: 'הכל',
  'All {{pluralName}}': `כל {{pluralName}}`,
  'All contacts': 'כל אנשי הקשר',
  'New leads': 'לידים חדשים',
  'Irrelevant leads': 'לידים לא רלוונטיים',
  Main: 'ראשי',
  'All companies': 'כל החברות',
  'Companies and organizations': 'חברות וארגונים',
  // products template
  'Products and services': 'מוצרים ושירותים',
  'All products and services': 'כל המוצרים והשירותים',
  Product: 'מוצר',
  Products: 'מוצרים',
  Leads: 'לידים',
  'Leads Layout': 'תצוגת לידים',
  'Available fields': 'שדות זמינים',
  'Fields to display': 'שדות לתצוגה',
  'All fields are displayed': 'כל השדות מוצגים',
  'Layout filters': 'מסננים לתצוגה',
  'Layout filters match statuses set in the current layout. Cards that meet these filters will be included in the selected layout.':
    'מסנני התצוגה תואמים לסטטוסים שנקבעו בתצוגה הנוכחית. כרטיסים שעומדים במסננים האלו ישויכו לתצוגה שנבחרה.',
};
