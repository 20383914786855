import { DirectionProvider, TextInput, type TextInputProps } from '@mantine/core';
import { useController, useFormContext } from 'react-hook-form';
import { type IBaseFormInputProps } from '../shared/FormInputTypes';
import { Label } from '../shared/Label';
import { parseFormErrorMessage } from '../shared/error-message.util';
import { useInputDirection } from '../shared/input.direction.hook';
import style from './Input.module.css';
export type FormInputProps = TextInputProps & IBaseFormInputProps;

export const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  description,
  required = false,
  leftSection,
  hideError = false,
  placeholder,
  dir,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { value, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = parseFormErrorMessage(error?.message);
  const directionProps = useInputDirection({ dir, placeholder, leftSection, value });

  return (
    <DirectionProvider initialDirection={directionProps.dir}>
      <TextInput
        className={style.input}
        id={name}
        value={(value as string) || ''}
        label={label ? <Label label={label} description={description} required={required} /> : undefined}
        error={hideError ? Boolean(errorMessage) : errorMessage}
        placeholder={placeholder}
        {...directionProps}
        {...rest}
        {...props}
      />
    </DirectionProvider>
  );
};
