import { z } from 'zod';

const TRIAL_EXPIRATION = 14 * 24 * 60 * 60 * 1000;

export const planSettingsSchema = z.object({
  // default 14 days from now
  expirationDate: z.coerce
    .date()
    .default(() => new Date(Date.now() + TRIAL_EXPIRATION))
    .optional(),
  /* Max number of users */
  usersLimit: z.number(),
  /* Limit in GB */
  storageLimit: z.number(),
  /* Max number of cards */
  cardsLimit: z.number(),
  /* Max number of card types */
  cardTypesLimit: z.number(),
  /* Max number of activities */
  activitiesLimit: z.number(),
  // Capabilities - list of capabilities
  capabilities: z.object({
    reports: z.object({ maxReports: z.number(), enabled: z.boolean() }),
    automation: z.object({
      limit: z.number(),
      enabled: z.boolean(),
    }),
    mailing: z.object({ maxPerMonth: z.number(), enabled: z.boolean() }),
    sms: z
      .object({
        maxPerMonth: z.number(),
        enabled: z.boolean(),
        balance: z.number().default(0),
      })
      .default({ maxPerMonth: 0, enabled: false, balance: 0 }),
    finances: z.object({ enabled: z.boolean() }).default({ enabled: false }),
    billing: z.object({ enabled: z.boolean(), maxTransactionsPerMonth: z.number() }),
    telephony: z.object({ enabled: z.boolean() }),
    api: z.object({
      /* Max number of api calls */
      maxCallsPerMonth: z.number(),
      enabled: z.boolean(),
    }),
  }),
  subscription: z
    .object({
      payPal: z.object({ planId: z.string(), subscriptionId: z.string() }).optional(),
    })
    .default({}),
});

export type PlanSetting = z.input<typeof planSettingsSchema>;
export type PlanSettingOutput = z.output<typeof planSettingsSchema>;

// Subscription
export const subscriptionSchema = z.object({
  planId: z.string(),
  subscriptionId: z.string(),
});

export type SubscriptionInput = z.input<typeof subscriptionSchema>;
