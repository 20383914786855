import { useCallback } from 'react';
import { clientConfig } from '~/core/config/config';
import { useScript } from '~/utils/use-script';

declare global {
  interface Window {
    dataLayer?: any[];
    gtag?: (...args: any[]) => void;
  }
}

const initializeGtag = ({ id }: { id: string }) => {
  if (!clientConfig.googleAds.enable) return;

  window.dataLayer = window.dataLayer || [];

  window.gtag = function gtag(...args: any[]) {
    window.dataLayer!.push(args);
  };
  window.gtag('js', new Date());
  window.gtag('config', id);
};

export const useGoogleAds = () => {
  const enable = clientConfig.googleAds.enable;
  useScript({
    url: `https://www.googletagmanager.com/gtag/js?id=${clientConfig.googleAds.id.next}`,
    enable,
    onload: () => initializeGtag({ id: clientConfig.googleAds.id.next }),
  });

  useScript({
    url: `https://www.googletagmanager.com/gtag/js?id=${clientConfig.googleAds.id.legacy}`,
    enable,
    onload: () => initializeGtag({ id: clientConfig.googleAds.id.legacy }),
  });

  const fireGoogleConversion = useCallback((key: keyof typeof clientConfig.googleAds.conversions) => {
    if (!enable) return;
    const conversions = clientConfig.googleAds.conversions[key];

    if (!Array.isArray(conversions)) {
      console.warn(`Invalid conversion key: ${key}`);
      return;
    }

    if (typeof window.gtag === 'function') {
      for (const { gtagId, label, value, currency } of conversions) {
        window.gtag('event', 'conversion', {
          send_to: `${gtagId}/${label}`,
          value,
          currency,
        });
      }
    } else {
      console.warn('Google Ads tracking (gtag) is not initialized');
    }
  }, []);

  return { fireGoogleConversion };
};
