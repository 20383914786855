export const dateComponent = {
  Absolute: 'Abszolút',
  Relative: 'Relatív',
  Apply: 'Alkalmaz',
  'Choose dates': 'Válassz dátumokat',
  Today: 'Ma',
  Yesterday: 'Tegnap',
  'Last 7 days': 'Elmúlt 7 nap',
  'Last 30 days': 'Elmúlt 30 nap',
  'Last week': 'Múlt hét',
  'This week': 'Ezen a héten',
  'This month': 'Ebben a hónapban',
  'Last month': 'Múlt hónap',
  'This year': 'Ebben az évben',
  'Last year': 'Múlt év',
  'Until now': 'Mostanáig',
  'From today': 'Mától',
  From: 'Ettől',
  To: 'Eddig',

  // Schedule
  'repeat every': 'Ismétlődjön minden',
  repeat: 'Ismétlés',
  day: 'nap',
  week: 'hét',
  month: 'hónap',
  year: 'év',
  days: 'napok',
  weeks: 'hetek',
  months: 'hónapok',
  years: 'évek',
  'days of week': 'A hét napjai',
  'day of month': 'Hónap napja',
  at: 'ekkor',
  sunday: 'vasárnap',
  monday: 'hétfő',
  tuesday: 'kedd',
  wednesday: 'szerda',
  thursday: 'csütörtök',
  friday: 'péntek',
  saturday: 'szombat',

  // Repeat
  never: 'soha',
  date: 'dátum',
  times: 'alkalommal',
  'week of month': 'hónap hétje',
  'day of week': 'hét napja',
  ends: 'vége',
  'end of repeat': 'ismétlés vége',
  'end after times': 'vége után alkalommal',
};
