import {
  IconApi,
  IconArchive,
  IconBackhoe,
  IconBrandWhatsapp,
  IconBuilding,
  IconBusinessplan,
  IconCalendarEvent,
  IconCards,
  IconCheckbox,
  IconDashboard,
  IconHistory,
  IconLockAccess,
  IconSettings,
  IconSettingsAutomation,
  IconStar,
  IconUser,
  IconUsersGroup,
} from '@tabler/icons-react';
import { type TFunction } from 'i18next';
import { useCardsNavigationHistory } from '~/Components/Cards/CardsTable/cards-table-navigation.hook';
import {
  CardBreadcrumb,
  CardTypeBreadcrumb,
  OrganizationBreadcrumb,
} from '../Header/AppBreadcrumbs/SubItemBreadcrumb';
import { type IMenuItem } from './types';

export const getMainMenuItems = (t: TFunction<'navbarComponent'>): IMenuItem[] => [
  {
    id: 'dashboard',
    title: t('Dashboard'),
    Icon: IconDashboard,
    featureFlag: 'dashboard',
    path: '/dashboard',
    hide: true,
  },
  {
    id: 'cards',
    title: null,
    desc: t('All Cards'),
    Icon: IconCards,
    showItemBreadcrumb: ({ id }) => Boolean(id),
    getBreadcrumbBackUrl: (params) => {
      const slug = typeof params.slug === 'string' ? params.slug : null;
      const lastCardsTableUrl = useCardsNavigationHistory.getState().url;
      if (slug && lastCardsTableUrl?.includes(slug)) return lastCardsTableUrl;
      if (typeof params.slug === 'string') {
        return `/cards/${params.slug}`;
      }
      return '/cards';
    },
    Breadcrumb: CardBreadcrumb,
    path: '/cards',
    children: [],
  },
  {
    id: 'tasks',
    title: t('Tasks'),
    Icon: IconCheckbox,
    path: '/tasks',
  },
  {
    id: 'agenda',
    title: t('Agenda'),
    Icon: IconCalendarEvent,
    path: '/agenda',
  },
  {
    id: 'finances',
    title: t('Finances'),
    Icon: IconBusinessplan,
    path: '/finances',
    featureFlag: 'finances',
    plan: (plan) => plan.capabilities.finances?.enabled,
    permission: ['FINANCE_DOCUMENTS', 'read'],
    hide: true,
    children: [
      {
        id: 'reports',
        title: t('Documents & Reports'),
        path: '/finances/reports',
        Icon: IconBusinessplan,
      },
      {
        id: 'overview',
        title: t('Dashboard'),
        path: '/finances/overview',
        Icon: IconDashboard,
      },
    ],
  },
  {
    id: 'automations',
    title: t('Automations'),
    Icon: IconSettingsAutomation,
    path: '/automations',
    plan: (plan) => plan.capabilities.automation?.enabled,
    permission: ['AUTOMATION', 'read'],
    featureFlag: 'automation',
    hide: true,
  },
  {
    id: 'conversations',
    title: t('Conversations'),
    Icon: IconBrandWhatsapp,
    path: '/conversations',
    featureFlag: 'conversations',
    hide: true,
  },
  {
    id: 'favorites',
    hide: true,
    title: t('Favorites'),
    Icon: IconStar,
    path: '/favorites',
  },
  {
    id: 'recent',
    hide: true,
    title: t('Recent'),
    Icon: IconHistory,
    path: '/recent',
  },
];

export const getSettingsMenuItems = (
  t: TFunction<'navbarComponent'>,
  isAdminConsole = false,
): IMenuItem[] => [
  {
    id: 'admin-console',
    hide: !isAdminConsole,
    title: t('Admin Console'),
    Icon: IconBackhoe,
    path: '/admin-console/organizations',
    children: [
      {
        id: 'admin-console',
        title: t('Organizations'),
        path: '/admin-console/organizations',
        Icon: IconBackhoe,
        showItemBreadcrumb: ({ orgId }) => Boolean(orgId),
        Breadcrumb: OrganizationBreadcrumb,
      },
    ],
  },
  {
    id: 'settings',
    title: t('Settings'),
    Icon: IconSettings,
    path: '/settings',
    children: [
      {
        id: 'organization',
        title: t('Organization'),
        path: '/settings/organization',
        permission: ['ORGANIZATION', 'read'],
        Icon: IconBuilding,
      },
      {
        id: 'cardTypes',
        title: t('Card Types'),
        path: '/settings/card-types',
        showItemBreadcrumb: ({ id }) => Boolean(id),
        Breadcrumb: CardTypeBreadcrumb,
        Icon: IconCards,
      },
      {
        id: 'plan-and-billing',
        title: t('Plan and Billing'),
        path: '/settings/plan-and-billing',
        permission: ['ORGANIZATION', 'read'],
        Icon: IconBusinessplan,
      },
      {
        id: 'members',
        title: t('Members'),
        path: '/settings/members',
        Icon: IconUsersGroup,
        permission: ['ORGANIZATION', 'read'],
      },
      {
        id: 'roles-and-permissions',
        title: t('Roles and Permissions'),
        path: '/settings/roles-and-permissions',
        permission: ['ORGANIZATION', 'update'],
        Icon: IconLockAccess,
      },
      {
        id: 'user',
        title: t('User Settings'),
        path: '/settings/user',
        Icon: IconUser,
      },
      {
        id: 'api',
        title: t('API Keys'),
        path: '/settings/api',
        permission: ['ORGANIZATION', 'read'],
        Icon: IconApi,
      },
      {
        id: 'finance',
        title: t('Finances'),
        path: '/settings/finance',
        featureFlag: 'finances',
        permission: ['FINANCE_DOCUMENTS', 'read'],
        Icon: IconBusinessplan,
      },
      {
        id: 'files-archive',
        title: t('Files Archive'),
        path: '/settings/archive',
        permission: ['STORAGE', 'read'],
        Icon: IconArchive,
      },
      {
        id: 'audit',
        title: t('Audit'),
        path: '/settings/audit',
        permission: ['AUDIT', 'read'],
        Icon: IconHistory,
      },
    ],
  },
];
