'use client';
import 'dayjs/locale/he';
export const dynamic = 'force-dynamic';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { useTranslation } from 'react-i18next';
import { useLocale } from '~/ui/hooks/locale.hook';

export const StyleProviderClient: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { locale, firstDayOfWeek, weekendDays } = useLocale();
  const { t } = useTranslation();

  return (
    <DatesProvider settings={{ locale, firstDayOfWeek, weekendDays }}>
      <ModalsProvider labels={{ cancel: t('Cancel'), confirm: t('Confirm') }}>{children}</ModalsProvider>
    </DatesProvider>
  );
};
