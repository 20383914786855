import { z } from 'zod';
import { env } from '~/env.mjs';

const googleAdsConversionSchema = z.object({
  label: z.string(),
  value: z.number(),
  currency: z.string(),
  gtagId: z.string(),
});

export const clientConfigSchema = z.object({
  googleAds: z
    .object({
      conversions: z
        .object({
          lead: z.array(googleAdsConversionSchema).default([]),
          signUp: z.array(googleAdsConversionSchema).default([]),
          organizationCreated: z.array(googleAdsConversionSchema).default([]),
        })
        .default({}),
      id: z
        .object({
          next: z.string().default(''),
          legacy: z.string().default(''),
        })
        .default({}),
      enable: z.boolean().default(false),
    })
    .default({}),
  isServer: z.boolean().default(typeof window === 'undefined'),
  isClient: z.boolean().default(typeof window !== 'undefined'),
  origin: z
    .string()
    .default(typeof window === 'undefined' ? 'http://localhost:3050' : window.location.origin),
  paypal: z
    .object({
      clientId: z.string().default(env.NEXT_PUBLIC_PAYPAL_CLIENT_ID ?? ''),
    })
    .default({}),
});

export type ClientConfigInput = z.input<typeof clientConfigSchema>;
export type ClientConfig = z.output<typeof clientConfigSchema>;
