import { Avatar, type ComboboxItem, Group, Stack, Text } from '@mantine/core';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clerkClientUtils } from '~/core/auth/clerk.utils';
import { api } from '~/trpc';
import { useMemberships } from '~/ui/hooks/organization-memberships.hook';
import { zodUtils } from '~/utils/zod-utils';
import { useCard } from '../Card/card.hook';
import { type PersonSelectItem } from './Lookup/UserSelect';
import { FormMultiSelect, type FormMultiSelectProps } from './MultiSelect';

type EmailMultiSelectProps = Omit<FormMultiSelectProps, 'data'> & {
  more?: ComboboxItem[];
  cardId?: string | null;
  allowAddMails?: boolean;
};

export type EmailSelectItem = ComboboxItem & { avatar?: string };

export const Email = forwardRef<HTMLDivElement, PersonSelectItem>(
  ({ label, value, avatar, ...others }: PersonSelectItem, ref) => {
    const sameValueAsLabel = value === label;
    return (
      <Stack gap={2}>
        <Group ref={ref} {...others} gap={'xs'}>
          <Avatar src={avatar} size={'xs'} />
          {label}
        </Group>
        {sameValueAsLabel ? null : (
          <Text fz={'xs'} c="dimmed">
            {value}
          </Text>
        )}
      </Stack>
    );
  },
);

Email.displayName = 'Person';

export const FormEmailMultiSelect: React.FC<EmailMultiSelectProps> = ({
  name,
  more = [],
  cardId,
  allowAddMails,
  ...props
}) => {
  const memberships = useMemberships();
  const { t } = useTranslation('fieldsComponent');
  const [emails, setEmails] = useState<ComboboxItem[]>([]);

  const { data: related = { results: [] }, isLoading } = api.cards.related.useQuery(
    { id: cardId! },
    { enabled: Boolean(cardId), staleTime: 5 * 60 * 1000, gcTime: 10 * 60 * 1000 },
  );

  const { card } = useCard(cardId);

  const data = useMemo(() => {
    const users =
      memberships?.data?.map((member) => ({
        label: clerkClientUtils.getFullName(member),
        value: member.publicUserData.identifier,
        avatar: member.publicUserData.imageUrl,
      })) ?? [];
    const relatedEmails = [...(card ? [card] : []), ...related.results]
      .map(({ name, email, avatar }) => ({
        label: name,
        value: email!,
        avatar,
      }))
      .filter(({ value }) => typeof value === 'string');
    const all = [...emails, ...more, ...users, ...relatedEmails];
    // remove duplicates emails
    const unique = all.filter(
      (item, index, self) => self.findIndex((t) => t.value === item.value) === index,
    );
    return unique;
  }, [memberships?.data, more, emails, related, card]);

  const handleOnCreate = useCallback((item: string) => {
    const email = zodUtils.emailSchema.safeParse(item);
    if (email.success) {
      setEmails((current) => [...current, { label: item, value: item }]);

      return item;
    }
    return false;
  }, []);

  return (
    <FormMultiSelect
      placeholder={t('Select emails...')}
      name={name}
      loading={isLoading}
      {...props}
      ItemComponent={Email}
      data={data}
      {...(allowAddMails
        ? {
            creatable: true,
            getCreateLabel: (item: string) => `+ ${t('Add an email')} ${item}`,
            onCreate: handleOnCreate,
          }
        : {})}
    />
  );
};
