'use client';
import { Stack } from '@mantine/core';
import { BackgroundImages } from '~/Components/Home/BackgroundImages';
import { HomeDescription } from '~/Components/Home/Description';
import { HomeFeatures } from '~/Components/Home/Features';
import { GetInTouch } from '~/Components/Home/GetInTouch';
import { HomeSecurity } from '~/Components/Home/Security';
import { HomeHero } from '../Components/Home/Hero';

export default function Page() {
  return (
    <div style={{ position: 'relative', zIndex: 0 }}>
      <BackgroundImages />
      <Stack
        pos={'relative'}
        style={{
          flex: 1,
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        p={'none'}
      >
        <HomeHero />
        <HomeDescription />
        <HomeFeatures />
        <HomeSecurity />
        <GetInTouch />
      </Stack>
    </div>
  );
}
