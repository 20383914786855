export const headerComponent = {
  workspace: 'Munkaterület',
  'Active users': 'Aktív felhasználók',
  Account: 'Vállalat',
  'Search {{cardType}}': '{{cardType}} keresése',
  'Cards Type': 'Kártyatípusok',
  Login: 'Bejelentkezés',
  All: 'Összes',
  login: 'Bejelentkezés',
  Previous: 'Előző',
  Next: 'Következő',
  'Back to App': 'Vissza az alkalmazáshoz',
  'Sign In': 'Bejelentkezés',
  'Create new organization': 'Új szervezet létrehozása',
  'No results found': 'Nincs találat',
  Menu: 'Menü',
};
