import { z } from 'zod';
import { dateTimeService } from '~/utils/date-time.service';
import { languages } from '~db/schema/consts/general';

// Account Settings
export const accountSettingsSchema = z.object({
  language: z.enum(languages).default('he'),
  timeZone: z.enum(dateTimeService.timeZones.all).default('Asia/Jerusalem'),
  phone: z.string().trim().optional(),
  email: z.string().trim().optional(),
  address: z.string().optional(),
  sms: z
    .object({
      // only characters and numbers, max 11 characters
      sender: z
        .string()
        .trim()
        .max(11)
        .refine((v) => /^[a-zA-Z0-9]*$/.test(v))
        .optional(),
    })
    .optional()
    .default({}),
  integrations: z
    .object({
      google: z
        .object({
          credentials: z
            .object({
              refreshToken: z.string().optional(),
              scope: z.string().optional(),
              accessToken: z.string().optional(),
              accessTokenExpiresAt: z.string().optional(),
            })
            .default({}),
        })
        .default({}),
    })
    .default({}),
});

export type IAccountSettings = z.infer<typeof accountSettingsSchema>;
export type IUpdateAccountSettings = z.input<typeof accountSettingsSchema>;
