import { type TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc';
import { type ClientErrorCodes } from '~/server/services/errors/error-codes';

export const errorCodes: Record<ClientErrorCodes | TRPC_ERROR_CODE_KEY, string> = {
  MISSING_PERMISSION_DEFINITION: 'אין לך הרשאה מוגדרת לפעולה זו',
  MISSING_ROLE: 'אין לך תפקיד מוגדר',
  NO_PERMISSION: 'אינך רשאי לבצע פעולה זו',
  CONTACT_ADMIN: 'אנא צור קשר עם מנהל המערכת',
  GENERAL_ERROR: 'אירעה שגיאה',
  CONTACT_SUPPORT: 'אנא צור קשר עם התמיכה',
  CARD_TYPE_DELETE_HAS_CARDS: 'לא ניתן למחוק סוג כרטיס עם כרטיסים קיימים',
  UNAUTHORIZED_NO_ORG: 'אינך מורשה לבצע פעולה זו',
  FINANCE_SETTINGS_NOT_FOUND: 'הגדרות מסמכים חשבונאיים לא נמצאו - עבור להגדרות פיננסיות וצור אותם',
  SMS_DISABLED: 'שירות ה-SMS מושהה עבור תוכנית הארגון הנוכחית - אנא צור קשר עם התמיכה',
  SMS_LIMIT_REACHED: 'הגעת למגבלת ה-SMS שלך - צור קשר עם התמיכה כדיי לרכוש עוד',
  INVALID_INPUT: 'קלט לא תקין',
  // TRPC errors
  BAD_REQUEST: 'בקשה לא תקינה',
  CLIENT_CLOSED_REQUEST: 'בקשה נסגרה על ידי הלקוח',
  CONFLICT: 'קיים כבר',
  FORBIDDEN: 'אין לך הרשאה לבצע פעולה זו',
  INTERNAL_SERVER_ERROR: 'אירעה שגיאה בשרת',
  METHOD_NOT_SUPPORTED: 'פעולה זו אינה נתמכת',
  NOT_FOUND: 'הישות שביקשת לא נמצאה',
  NOT_IMPLEMENTED: 'פעולה זו אינה נתמכת',
  PARSE_ERROR: 'שגיאה בניתוח הנתונים',
  PAYLOAD_TOO_LARGE: 'הגודל גדול מדי',
  PRECONDITION_FAILED: 'פעולה זו אינה נתמכת',
  TIMEOUT: 'הזמן פג',
  TOO_MANY_REQUESTS: 'יותר מדי בקשות',
  UNAUTHORIZED: 'אינך מורשה לבצע פעולה זו',
  UNPROCESSABLE_CONTENT: 'תוכן לא תקין',
  UNSUPPORTED_MEDIA_TYPE: 'סוג מדיה לא נתמך',
  BAD_GATEWAY: 'שער לא תקין',
  GATEWAY_TIMEOUT: 'זמן המתנה פג',
  SERVICE_UNAVAILABLE: 'השירות אינו זמין',
};
