export const settingsPage = {
  'Missing Google account': 'Hiányzó Google-fiók',
  'You need to connect your Google account first': 'Először csatlakoztassa Google-fiókját',
  "Gmail's connection is active": 'A Gmail kapcsolat aktív',
  'Connect Gmail to system': 'Csatlakoztassa a Gmail-t a rendszerhez',
  'Account info': 'Fiók adatai',
  'API Tokens': 'API-kulcsok',
  'API Explorer': 'API-felderítő',
  'User Profile': 'Felhasználói profil',
  Organization: 'Szervezet',
  'Token copied': 'Token másolva',
  'Token copied to clipboard': 'A token a vágólapra másolva',
  Token: 'Token',
  'Card Identifier': 'Kártyaazonosító',
  Name: 'Név',
  'Try it out': 'Próbálja ki',
  'Get Record': 'Rekord lekérése',
  'Create Record': 'Rekord létrehozása',
  'Update Record': 'Rekord frissítése',
  'Delete Record': 'Rekord törlése',
  Search: 'Keresés',
  Action: 'Művelet',
  'Card Type': 'Kártyatípus',
  'Request URL': 'Kérés URL-je',
  'Request example': 'Kérés példa',
  'Test Request': 'Tesztkérés',
  Result: 'Eredmény',
  Copied: 'Másolva',
  Copy: 'Másolás',
  Fields: 'Mezők',
  Field: 'Mező',
  'Add Field': 'Mező hozzáadása',
  'Required fields are automatically added': 'A kötelező mezők automatikusan hozzáadódnak',
  'Add more fields to the request by selecting them from the dropdown':
    'Adjon hozzá további mezőket a kéréshez a legördülő listából való kiválasztással',
  'You can also add default values for the fields': 'Alapértelmezett értékeket is hozzáadhat a mezőkhöz',
  'Default value': 'Alapértelmezett érték',

  // Organization
  'Basic member': 'Alap tag',
  Admin: 'Adminisztrátor',
  Members: 'Tagok',
  Invitations: 'Meghívók',
  Email: 'E-mail',
  Joined: 'Csatlakozott',
  Actions: 'Műveletek',
  'Invited at': 'Meghívva',
  Revoke: 'Visszavonás',
  Remove: 'Eltávolítás',
  'Invitation sent': 'Meghívó elküldve',
  'Invitation was sent successfully to {{email}}': 'A meghívó sikeresen elküldve {{email}} címre',
  'System Role': 'Rendszerszerep',
  'Organization Role': 'Szervezeti szerep',
  Invite: 'Meghívás',
  'Remove member': 'Tag eltávolítása',
  'Manage organization members': 'Szervezeti tagok kezelése',
  'Are you sure that you want to remove {{member}} from the organization?':
    'Biztosan eltávolítja {{member}}-t a szervezetből?',
  'Revoke invitation': 'Meghívás visszavonása',
  'Are you sure that you want to revoke invitation to {{member}}?':
    'Biztosan visszavonja a meghívót {{member}} részére?',
  'No pending invitations': 'Nincsenek függőben lévő meghívók',
  'Invite member': 'Tag meghívása',
  'Member removed': 'Tag eltávolítva',
  '{{member}} was successfully removed from the organization':
    '{{member}} sikeresen eltávolítva a szervezetből',
  'Organizations relations': 'Szervezeti kapcsolatok',
  organizationsRelationsDescription: `Itt beállíthatja, mely szervezetek érhetők el az egyes szerepek számára.
  Például, ha hozzáférést szeretne adni az "A" szervezethez a "B" szerephez, válassza az "A" szervezetet a hozzáférési oszlopban, majd kattintson a "Mentés" gombra.
  Ehhez a szervezetének szülőszervezetnek kell lennie a kapcsolódó szervezetek számára. Ha módosítani szeretné a szülőszervezetet, vegye fel a kapcsolatot az adminisztrátorral.`,
  organizationsRelationsDescriptionNoOrgs: `Nincsenek alárendelt szervezetek, vagy a szervezete nem szülőszervezet.`,
  'No child organizations': 'Nincsenek alárendelt szervezetek',

  // User
  'User Profile and Security': 'Felhasználói profil és biztonság',
  'User Settings': 'Felhasználói beállítások',
  'General Settings': 'Általános beállítások',
  Language: 'Nyelv',
  Phone: 'Telefon',
  Notifications: 'Értesítések',
  'Emails will be sent to the following address: {{email}}':
    'Az e-mailek a következő címre kerülnek elküldésre: {{email}}',
  'Email notifications': 'E-mail értesítések',
  'Receive notifications for meetings, tasks, reminders, and mentions':
    'Kapjon értesítéseket találkozókról, feladatokról, emlékeztetőkről és említésekről',
  'Receive notifications for meetings': 'Kapjon értesítéseket találkozókról',
  'Receive notifications for tasks': 'Kapjon értesítéseket feladatokról',
  'Receive notifications for mentions': 'Kapjon értesítéseket említésekről',
  'Enable or disable web notifications': 'Webes értesítések engedélyezése vagy letiltása',
  'System notifications': 'Rendszerértesítések',
  Mentions: 'Említések',
  Meetings: 'Találkozók',
  Tasks: 'Feladatok',
  'User settings updated': 'Felhasználói beállítások frissítve',
  'Your user settings have been updated': 'Felhasználói beállításai frissítve lettek',
  // Plan
  'Current plan': 'Terv jelenlegi állapota',
  Billing: 'Számlázás',
  '{{usersLimit}} users': '{{usersLimit}} felhasználó',
  '{{cardsLimit}} cards': '{{cardsLimit}} kártya',
  '{{cardTypesLimit}} card types': '{{cardTypesLimit}} kártyatípus',
  '{{activitiesLimit}} activities': '{{activitiesLimit}} tevékenység',
  '{{storageLimit}} storage': '{{storageLimit}} tárhely',
  Capabilities: 'Képességek',
  'Your trial plan will expire {{expiredIn}}': 'Próbaverziója lejár {{expiredIn}} idő múlva',
  'Please upgrade your plan to continue using the app - contact support for more information':
    'Frissítse tervét az alkalmazás használatának folytatásához - további információért lépjen kapcsolatba a támogatással',
  'Content is not a part of the plan': 'A tartalom nem része a tervnek',
  'Please contact support to upgrade your plan':
    'Kérjük, vegye fel a kapcsolatot a támogatással a terv frissítéséhez',
  'You do not have permission to access this page': 'Nincs engedélye az oldal elérésére',
  'Please contact your organization administrator':
    'Vegye fel a kapcsolatot a szervezet adminisztrátorával',
  "Next payment's date": 'Következő fizetés dátuma',
  Usage: 'Használat',
  'out of': 'a következőből',
  '{{limit}} left': '{{limit}} maradt',
  'No balance left': 'Nincs egyenleg',
  'Limit exceeded by {{diff}}': 'A határértéket túllépték {{diff}}-al',
  Amount: 'Összeg',
  planType: {
    FREE: 'Ingyenes',
    TRIAL: 'Próbaidőszak',
    STARTER: 'Kezdő',
    PRO: 'Profi',
    ENTERPRISE: 'Vállalati',
  },
  'Plan and Billing': 'Terv és számlázás',
  'Plan updated': 'Terv frissítve',
  'Plan updated successfully': 'A terv sikeresen frissült',
  Plan: 'Terv',
  'Plan end date': 'A terv lejárati dátuma',
  'SMS Balance': 'SMS-egyenleg',
  Save: 'Mentés',
  reports: 'Jelentések',
  automation: 'Automatizálás',
  mailing: 'E-mailezés',
  sms: 'SMS',
  finances: 'Pénzügyek',
  billing: 'Számlázás',
  telephony: 'Telekommunikáció',
  Automations: 'Automatizálások',
  'Your trial plan has expired': 'A próbaidőszak lejárt',
  'Please contact support to continue using the app':
    'Az alkalmazás használatához vegye fel a kapcsolatot a támogatással',
  'Failed to subscribe': 'A feliratkozás sikertelen',
  'Subscribed successfully to {{planType}}': 'Sikeresen feliratkozott a(z) {{planType}}-ra',
  'Your subscription has been activated': 'A feliratkozása aktiválva lett',

  // SMS
  Success: 'Siker',
  'Sender id has been verified successfully': 'A küldő azonosítója sikeresen ellenőrizve',
  'Sender id already allowed': 'A küldő azonosítója már engedélyezett',
  'Sender id has already been verified, settings updated with the new sender id':
    'A küldő azonosítója már ellenőrizve lett, a beállítások frissültek az új azonosítóval',
  'Sender id verification failed': 'A küldő azonosítójának ellenőrzése sikertelen',
  'SMS - sender name': 'SMS - küldő neve',
  'Sender name that will appear on the SMS - up to 11 characters - can be a phone number or a name':
    'A küldő neve, amely az SMS-ben megjelenik - legfeljebb 11 karakter - lehet telefonszám vagy név',
  'Change sender id': 'Küldő azonosítójának módosítása',
  'Enter the OTP code that you received': 'Adja meg a kapott OTP-kódot',
  'Add sender id': 'Küldő azonosítójának hozzáadása',
  'OTP code sent to': 'OTP-kód elküldve ide:',
  'OTP code': 'OTP-kód',
  'Verify OTP': 'OTP ellenőrzése',
  'Enter sender id to verify': 'Adja meg az ellenőrizendő küldő azonosítóját',
  'Send OTP': 'OTP küldése',

  // Api Explorer
  api: 'API',
  'Took {{duration}}ms': '{{duration}}ms alatt hajtva végre',
  Error: 'Hiba',
  Get: 'Lekérdezés',
  Create: 'Létrehozás',
  'Sort Field': 'Rendezési mező',
  'Sort Order': 'Rendezési sorrend',
  Ascending: 'Növekvő',
  Descending: 'Csökkenő',
  Limit: 'Határérték',
  'Cursor is a number of records to skip. It is used for pagination.':
    'A kurzor a kihagyandó rekordok száma. Lapozáshoz használatos.',
  Cursor: 'Kurzor',

  // Usage
  Users: 'Felhasználók',
  Cards: 'Kártyák',
  'Card types': 'Kártyatípusok',
  SMS: 'SMS-ek',
  Activities: 'Tevékenységek',
  Storage: 'Tárhely',
  'Failed to load usage data': 'A használati adatok betöltése sikertelen',
  'Failed to load plan data': 'A tervadatok betöltése sikertelen',

  // Roles & Permissions
  'User Roles': 'Felhasználói szerepek',
  Organizations: 'Szervezetek',
  'Delete role': 'Szerep törlése',
  'Are you sure you want to delete role {{role}}?': 'Biztosan törli a(z) {{role}} szerepet?',
  'Cannot delete role - in use': 'A szerep nem törölhető - használatban van',
  'Permission denied': 'Hozzáférés megtagadva',
  'You are not authorized to perform {{action}} on {{type}}':
    'Nincs engedélye {{action}} végrehajtására {{type}}-on',
  'Update permissions': 'Engedélyek frissítése',
  'Please contact your organization owner': 'Lépjen kapcsolatba a szervezet tulajdonosával',
  'Create role': 'Szerep létrehozása',
  'New Role': 'Új szerep',
  Cancel: 'Mégsem',
  Owner: 'Tulajdonos',
  User: 'Felhasználó',
  Permission: 'Engedély',
  Read: 'Olvasás',
  Update: 'Frissítés',
  Delete: 'Törlés',
  Collaboration: 'Együttműködés',
  'Created at': 'Létrehozva',
  'Updated at': 'Frissítve',
  Role: 'Szerep',
  Roles: 'Szerepek',
  Permissions: 'Engedélyek',
  Access: 'Hozzáférés',
  collaboration: {
    ORGANIZATION: 'Szervezeti szint',
    ROLE_GROUP: 'Szerepcsoport szint',
    USER: 'Felhasználói szint',
  },
  permissionAction: {
    read: 'Olvasás',
    update: 'Frissítés',
    delete: 'Törlés',
    create: 'Létrehozás',
  },
  permissionType: {
    CARDS: 'Kártyák',
    AUTOMATION: 'Automatizálás',
    COMMENTS: 'Megjegyzések',
    TASKS: 'Feladatok',
    STORAGE: 'Tárhely',
    AUDIT: 'Audit',
    MEETINGS: 'Találkozók',
    CARD_TYPES: 'Kártyatípusok',
    CARD_FIELDS: 'Kártyamezők',
    CARD_LAYOUTS: 'Kártyaelrendezések',
    CARDS_TABLE_VIEWS: 'Táblázat nézetek',
    ROLES: 'Szerepek',
    ADMIN_CONSOLE: 'Admin konzol',
    ORGANIZATION: 'Szervezet',
    FINANCE_DOCUMENTS: 'Pénzügyi dokumentumok',
    SMS: 'SMS-ek',
  },
};
