import { useOrganization, useOrganizationList, useSession } from '@clerk/nextjs';

export const useOrganizations = () => {
  const {
    userMemberships,
    isLoaded: isOrgsLoaded,
    setActive,
  } = useOrganizationList({ userMemberships: {} });
  const { organization, isLoaded: isOrgLoaded } = useOrganization();
  const { session } = useSession();

  const selectOrganization = async (orgId: string): Promise<void> => {
    await setActive?.({ organization: orgId, session });
  };

  return {
    userMemberships,
    organization,
    isLoading: !isOrgLoaded || !isOrgsLoaded,
    selectOrganization,
  };
};
