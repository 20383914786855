import { isEmptyString } from 'is-what';
import { regexSchemas } from '~/utils/regex';
import { FormInputProps } from '../Input/Input';

export const useInputDirection = ({
  dir: initialDir,
  placeholder,
  leftSection,
  value,
}: Pick<FormInputProps, 'dir' | 'placeholder' | 'value' | 'leftSection'>) => {
  const text = !value || isEmptyString(value) ? placeholder : value;
  const isLtr =
    typeof initialDir === 'string'
      ? initialDir === 'ltr'
      : Boolean(typeof text === 'string' && regexSchemas.startsWithEnglishAndNumbers.test(text));
  const finalDir = { dir: isLtr ? 'ltr' : 'rtl' } as const;
  const section = { ...(isLtr ? { rightSection: leftSection } : { leftSection }) };
  const styles = { label: { width: '100%' }, input: { textAlign: isLtr ? 'left' : 'right' } } as const;

  return {
    ...finalDir,
    ...section,
    styles,
  };
};
