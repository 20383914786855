import { TRPCError } from '@trpc/server';
import { z } from 'zod';
import { zodUtils } from './zod-utils';

export type ParsedError = {
  message: string;
  stack?: string;
  name: string;
  meta?: z.ZodError<unknown>;
};

const parse = (error: unknown): ParsedError => {
  if (typeof error === 'string') {
    return {
      message: error,
      name: 'error',
    };
  }
  if (error instanceof z.ZodError) {
    return {
      message: zodUtils.formatError(error),
      meta: error,
      name: 'invalid-schema',
    };
  }
  if (error instanceof Error) {
    return error;
  }
  if (error instanceof TypeError) {
    return error;
  }
  if (error instanceof TRPCError) {
    if (error.cause instanceof z.ZodError) {
      return {
        message: zodUtils.formatError(error.cause),
        meta: error.cause,
        name: error.name,
      };
    }
    return error;
  }
  return {
    message: 'Unknown error',
    name: 'error',
  };
};

export const errorService = {
  parse,
};
