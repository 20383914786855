export const activitiesComponent = {
  // Communication
  '{{type}} created': '{{type}} létrehozva',
  '{{type}} updated': '{{type}} frissítve',
  '{{type}} deleted': '{{type}} törölve',
  '{{type}} created successfully': '{{type}} sikeresen létrehozva',
  '{{type}} updated successfully': '{{type}} sikeresen frissítve',

  'Communication delete': 'Kommunikáció törölve',
  'Create communication': 'Kommunikáció létrehozása',
  'Edit communication': 'Kommunikáció szerkesztése',
  'Create {{type}}': '{{type}} létrehozása',
  'Edit {{type}}': '{{type}} szerkesztése',
  'for {{name}}': '{{name}} részére',
  'Communication type': 'Kommunikáció típusa',

  // Meeting
  'Create meeting': 'Találkozó létrehozása',
  'Edit meeting': 'Találkozó szerkesztése',
  'Meeting created': 'Találkozó létrehozva',
  'Meeting updated': 'Találkozó frissítve',
  'Meeting delete': 'Találkozó törölve',
  'Meeting created successfully': 'Találkozó sikeresen létrehozva',
  'Meeting updated successfully': 'Találkozó sikeresen frissítve',
  'Meeting with': 'Találkozó valakivel',
  'Send email invitations and updates': 'E-mail meghívók és frissítések küldése',
  'Send SMS invitations and updates': 'SMS meghívók és frissítések küldése',
  'Send SMS reminder and updates': 'SMS emlékeztetők és frissítések küldése',
  'Send SMS on changes, cancel and reminder':
    'SMS küldése módosítások, lemondások és emlékeztetők esetén',
  'send email invitations and update on rescheduling, canceling and reminders':
    'E-mail meghívók és frissítések küldése újratervezés, lemondás és emlékeztetők esetén',
  'send SMS invitations and update on rescheduling, canceling and reminders':
    'SMS meghívók és frissítések küldése újratervezés, lemondás és emlékeztetők esetén',

  // Task
  Tasks: 'Feladatok',
  'Create task': 'Feladat létrehozása',
  'Edit task': 'Feladat szerkesztése',
  'Task created': 'Feladat létrehozva',
  'Task updated': 'Feladat frissítve',
  'Task delete': 'Feladat törölve',
  'Task created successfully': 'Feladat sikeresen létrehozva',
  'Task updated successfully': 'Feladat sikeresen frissítve',
  'Task for': 'Feladat számára',

  // Audit
  'was changed from': 'módosítva innen:',
  'was changed from empty value': 'módosítva üres értékről',
  'The field': 'A mező',
  from: 'innen:',
  'to empty value': 'üres értékre',
  to: 'erre:',

  // Active reminders
  Reminders: 'Emlékeztetők',
  'Got it': 'Értem',
  'Remind me later': 'Emlékeztess később',
  'Remind me 5 minutes before': 'Emlékeztess 5 perccel előtte',
  'Remove reminder': 'Emlékeztető eltávolítása',

  '{{type}} reminder': '{{type}} emlékeztető',
  'Reminder for {{type} "{{title}}" \n that starts at {{time}}':
    'Emlékeztető a(z) {{type}} "{{title}}" számára, amely {{time}}-kor kezdődik',

  Error: 'Hiba',
  Description: 'Leírás',
  Location: 'Helyszín',
  Title: 'Cím',
  Date: 'Dátum',
  Card: 'Kártya',
  'Created By': 'Létrehozta',
  Update: 'Frissítés',
  Create: 'Létrehozás',
  Inbound: 'Bejövő',
  Outbound: 'Kimenő',
  When: 'Mikor',
  'Assigned To': 'Felelős',
  Participants: 'Résztvevők',
  'Add participants': 'Résztvevők hozzáadása',
  'Select communication type': 'Válassz kommunikációs típust',
  'Send from my Gmail': 'Küldés a Gmail-emről',
  "Gmail is not connected, configure it in User's Settings":
    'A Gmail nincs csatlakoztatva, konfiguráld a Felhasználói beállításokban',
  'Gmail is connected': 'A Gmail csatlakoztatva van',
  'Send SMS': 'SMS küldése',
  'No SMS plan found': 'Nincs SMS-csomag',
  'SMS is disabled in the current plan': 'Az aktuális csomagban az SMS le van tiltva',
  'You have no SMS left in your balance': 'Nincs több SMS a keretedben',
  '{{name}} does not have a phone number, please add one and try again':
    '{{name}} nem rendelkezik telefonszámmal, adj hozzá egyet, és próbáld újra',
  'Please set up a "sender number" for SMS in Organization Settings':
    'Állíts be egy "küldő számot" az SMS-hez a Szervezeti beállításokban',
  'You do not have permission to send SMS - please contact your administrator':
    'Nincs jogosultságod SMS küldésére - lépj kapcsolatba az adminisztrátorral',
  'No card found, please select a card and try again':
    'Nem található kártya, válassz egyet, és próbáld újra',
  'You have {{balance}} SMS left': 'Még {{balance}} SMS-ed maradt',
  'No tasks found': 'Nincs találat',
  Actions: 'Műveletek',

  Message: 'Üzenet',
  'Create without sending': 'Létrehozás küldés nélkül',
  'Send invitation': 'Meghívó küldése',
  'New Event': 'Új esemény',
  CALL: 'Telefonhívás',
  MAIL: 'E-mail',
  WHATSAPP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'Twitter',
  LINKEDIN: 'LinkedIn',
  SMS: 'SMS',
  TASK: 'Feladat',
  MEETING: 'Találkozó',
  COMMENT: 'Megjegyzés',
  // ai section
  'Based on the following history, write a new {{type}}, no more than a short paragraph':
    'Az alábbi előzmények alapján írj egy új {{type}}-ot, legfeljebb egy rövid bekezdésben',

  Duration: 'Időtartam',
  min: 'perc',
  All: 'Összes',
  Meetings: 'Találkozók',

  today: 'ma',
  upcoming: 'közelgő',
  past: 'elmúlt',
  filter: 'szűrő',
  'New Meeting': 'Új találkozó',
  'New Task': 'Új feladat',
  Until: 'Amíg',
  Reminder: 'Emlékeztető',
  Where: 'Hol',
  Assignee: 'Felelős',
  Status: 'Állapot',
  Done: 'Kész',
  'Is done': 'Kész van?',
  'Not done': 'Nincs kész',
  'minutes before': 'perccel előtte',
  'hour before': 'órával előtte',
  'day before': 'nappal előtte',
  None: 'Nincs',
  '5 minutes before': '5 perccel előtte',
  '10 minutes before': '10 perccel előtte',
  '15 minutes before': '15 perccel előtte',
  '30 minutes before': '30 perccel előtte',
  '45 minutes before': '45 perccel előtte',
  '1 hour before': '1 órával előtte',
  '1 day before': '1 nappal előtte',
  '15 minutes': '15 perc',
  '30 minutes': '30 perc',
  '45 minutes': '45 perc',
  '1 hour': '1 óra',
  '1 hour and 15 minutes': '1 óra 15 perc',
  '1 hour and 30 minutes': '1 óra 30 perc',
  '1 hour and 45 minutes': '1 óra 45 perc',
  '2 hours': '2 óra',
  minutes: 'perc',
  hour: 'óra',
  hours: 'órák',
  'Meeting duration': 'Találkozó időtartama',
  'Mark as done': 'Jelölés késznek',
  'Mark as undone': 'Jelölés nincs késznek',
  Add: 'Hozzáadás',
  'Task deleted': 'Feladat törölve',
  'Task deleted successfully': 'Feladat sikeresen törölve',
  'Meeting deleted': 'Találkozó törölve',
  'Meeting deleted successfully': 'Találkozó sikeresen törölve',
  'Communication deleted': 'Kommunikáció törölve',
  'Communication deleted successfully': 'Kommunikáció sikeresen törölve',
  Direction: 'Irány',
  Body: 'Tartalom',
  Attachments: 'Csatolmányok',
  Delete: 'Törlés',
  'Are you sure you want to delete this task?': 'Biztosan törölni szeretnéd ezt a feladatot?',
  'Are you sure you want to delete this meeting?': 'Biztosan törölni szeretnéd ezt a találkozót?',
  'Are you sure you want to delete this communication?':
    'Biztosan törölni szeretnéd ezt a kommunikációt?',
  'Yes, delete': 'Igen, törlés',
  'No, keep it': 'Nem, maradjon',
  'Filter by type': 'Szűrés típus szerint',
  ALL: 'Összes',
  Today: 'Ma',
  'Done?': 'Kész?',
  'Select the card to which you want to associate this task':
    'Válaszd ki a kártyát, amelyhez a feladatot társítani szeretnéd',
  'Select the card to which you want to associate this meeting':
    'Válaszd ki a kártyát, amelyhez a találkozót társítani szeretnéd',
  Remove: 'Eltávolítás',
  'Select a card': 'Kártya kiválasztása',
  'Due Date': 'Határidő',
  'Reminder?': 'Emlékeztető?',
  'New activity': 'Új tevékenység',

  // Quick filters
  Me: 'Én',
  Overdue: 'Lejárt',
  Unassigned: 'Nincs hozzárendelve',

  // Global comment
  'Add a comment, try using our': 'Adj hozzá egy megjegyzést, próbáld ki az',
  'AI assistant': 'AI asszisztenst',
};
