import { Button, type ButtonProps, type FloatingPosition, Tooltip } from '@mantine/core';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type SubmitButtonProps = {
  children?: React.ReactNode;
  label?: 'Submit' | 'Update' | 'Save' | 'Create';
  tooltipPosition?: FloatingPosition;
};

export const SubmitButton: React.FC<SubmitButtonProps & ButtonProps> = ({
  children,
  loading,
  label = 'Submit',
  tooltipPosition,
  disabled,
  ...props
}) => {
  const { isSubmitting, isValid } = useFormState();

  const { t } = useTranslation();
  const tooltipLabel = isValid ? null : t('Form is not valid, please check the fields');

  return (
    <Tooltip
      disabled={!tooltipLabel}
      label={tooltipLabel}
      position={tooltipPosition}
      withArrow
      color="gray"
    >
      <Button
        w={'fit-content'}
        {...props}
        loading={isSubmitting && loading}
        disabled={isSubmitting || disabled}
        type="submit"
      >
        {children ?? t(label)}
      </Button>
    </Tooltip>
  );
};
