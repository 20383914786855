import { Button, Card, FocusTrap, Stack } from '@mantine/core';
import { IconBrain, IconPencil, IconStars } from '@tabler/icons-react';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormTextarea, type FormTextareaProps } from '~/Components/Form/Textarea';
import { type ICreateActivity } from '~/server/services/activity/types';
import { api } from '~/trpc';
import { useLocale } from '~/ui/hooks/locale.hook';

export const AiAssistance: React.FC<Partial<FormTextareaProps>> = ({ label, ...props }) => {
  const formMethods = useFormContext<ICreateActivity>();
  const descriptionLabel = label ?? t('Description');
  const [aiAssisting, setAiAssisting] = useState(false);
  const { t: tAi } = useTranslation('aiComponent');

  const { mutateAsync, isPending: isAssisting } = api.ai.generateDescription.useMutation();

  const cardIdWatch = formMethods.watch('cardId');
  const type = formMethods.watch('type');

  const callAiAssistant = useCallback(async () => {
    if (!cardIdWatch) return;
    const description = formMethods.getValues('description');
    const result = await mutateAsync({ prompt: description ?? '', cardId: cardIdWatch, type });
    setAiAssisting(false);
    formMethods.setValue('description', result);
  }, [formMethods, mutateAsync, cardIdWatch]);
  const { align } = useLocale();
  return (
    <Stack gap={0} pos="relative">
      {aiAssisting && cardIdWatch ? (
        <FocusTrap active>
          <Card withBorder bg="transparent">
            <Stack>
              <FormTextarea
                name="description"
                autosize
                minRows={2}
                label={tAi('Use AI to help write content effectively')}
                placeholder={tAi('eg. "I need to call the client to discuss the upcoming project"')}
                rightSection={<IconBrain size="1rem" />}
              />
              <Button
                size="xs"
                w={'fit-content'}
                leftSection={<IconStars stroke={1} />}
                onClick={callAiAssistant}
                loading={isAssisting}
                variant="gradient"
                gradient={{ from: 'violet', to: 'grape' }}
              >
                {tAi('Generate description')}
              </Button>
            </Stack>
          </Card>
        </FocusTrap>
      ) : (
        <FormTextarea
          autoFocus
          autosize
          name="description"
          minRows={2}
          label={descriptionLabel}
          required
          {...props}
        />
      )}
      {cardIdWatch ? (
        <Button
          pos="absolute"
          top={0}
          {...(align === 'left' ? { right: '17px' } : { left: '17px' })}
          size="compact-sm"
          w="fit-content"
          variant={aiAssisting ? 'default' : 'gradient'}
          gradient={{ from: 'violet', to: 'grape' }}
          style={{ transform: 'translate(0%, 50%)' }}
          leftSection={
            aiAssisting ? <IconPencil size={17} stroke={1} /> : <IconStars size={17} stroke={1} />
          }
          onClick={() => setAiAssisting((prev) => !prev)}
        >
          {aiAssisting ? tAi('Write yourself') : tAi('Write with AI')}
        </Button>
      ) : null}
    </Stack>
  );
};
