import { useEffect } from 'react';

export const useScript = ({
  url,
  inlineScriptContent,
  enable = true,
  onload,
}: {
  url?: string;
  inlineScriptContent?: string;
  enable?: boolean;
  onload?: () => void;
}) => {
  useEffect(() => {
    if (!enable || (!url && !inlineScriptContent)) {
      return;
    }
    const script = document.createElement('script');

    if (url) {
      script.src = url;
      script.async = true;
      script.crossOrigin = 'true';
      script.type = 'module';
    } else if (inlineScriptContent) {
      script.innerHTML = inlineScriptContent;
    }

    const handleLoad = () => {
      onload?.();
    };

    script.onload = handleLoad;

    document.body.appendChild(script);

    return () => {
      if (enable && script.parentNode) {
        document.body.removeChild(script);
      }
    };
  }, [url, inlineScriptContent, enable, onload]);
};
