globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"c24d36504be462c47b281801e2c48b5d5219aee9"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { env } from '~/env.mjs';

Sentry.init({
  enabled: env.NEXT_PUBLIC_APP_ENV === 'production',

  dsn: 'https://16a334a645ee49c0b99befa73fee59d1@o4505408814645248.ingest.us.sentry.io/4505408818053120',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
