import { Anchor, Avatar, Badge, Code, Group, Text } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { LookupCardDisplay } from '~/Components/Form/Lookup/CardDisplay';
import { User } from '~/Components/Form/Lookup/UserSelect';
import PhoneInputView from '~/Components/Form/PhoneInput/PhoneInputView';
import { ColorDot } from '~/Components/Form/shared/SelectItemComponent';
import { type FieldProps } from '~/server/services/templates/fields';
import { dateTimeService } from '~/utils/date-time.service';
import { numberService } from '~/utils/number.service';
import { type Card, type FieldType } from '~db/types';

const renderOption = ({ value, field }: { value: unknown; field?: FieldProps }) => {
  if (!field?.options || field.options.length === 0) return value as string;
  const option = field.options.find((option) => option.value === value);
  if (!option) return value as string;
  if (option.color) {
    return <Badge color={option.color}>{option.label}</Badge>;
  }

  return option.label;
};

const renderOptions = ({ value: values, field }: { value: unknown; field?: FieldProps }) => {
  if (!field?.options || field.options.length === 0) return values as string;
  if (!Array.isArray(values)) return renderOption({ value: values, field });

  // only show 4 first elements
  let shownValues = values as string[];
  if (shownValues.length > 4) {
    shownValues = values.slice(0, 4);
    shownValues.push('...');
  }

  return (
    <Group gap={'xs'}>
      {shownValues.map((v) => (
        <Text key={v}>{renderOption({ value: v, field })}</Text>
      ))}
    </Group>
  );
};

export const mapFieldTypeToDisplayComponent: Partial<
  Record<
    FieldType,
    React.FC<{
      value: unknown;
      field?: FieldProps;
    }>
  >
> = {
  TEXT: ({ value, field }) => (
    <Text size="sm" data-system={field?.system}>
      {value as string}
    </Text>
  ),
  TEXTAREA: ({ value }) => <Text size="xs">{value as string}</Text>,
  JSON: ({ value }) =>
    value ? (
      <Code block mah={100} style={{ textAlign: 'left' }}>
        {JSON.stringify(value, null, 2)}
      </Code>
    ) : null,
  CHECKBOX: ({ value }) => (value ? <IconCheck /> : <IconX />),
  SINGLE_SELECT: renderOption,
  MULTI_SELECT: renderOptions,
  COLOR: ({ value, field }) => <ColorDot data-system={field?.system} color={value as string} />,
  NUMBER: ({ value }) => numberService.tryFormat(value),
  IMAGE_URL: ({ value, field }) => (
    <Avatar data-system={field?.system} src={value as string} size={24} />
  ),
  LOOKUP_USER: ({ value, field }) =>
    value ? <User data-system={field?.system} userId={value as string} /> : null,
  LOOKUP_CARD: ({ value }) => (value ? <LookupCardDisplay value={value} /> : null),
  EMAIL: ({ value, field }) => (
    <Text truncate data-system={field?.system} size="xs">
      {value as string}
    </Text>
  ),
  PHONE: ({ value, field }) => (
    <PhoneInputView data-system={field?.system} value={value as string} disableEdit />
  ),
  URL: ({ value, field }) =>
    typeof value === 'string' ? (
      <Anchor
        c="blue"
        href={value}
        data-system={field?.system}
        target="_blank"
        rel="noreferrer noopener"
      >
        {value}
      </Anchor>
    ) : null,
  DATE: ({ value }) =>
    value ? (
      <Text size="sm" title={dateTimeService.tryFormatDateTime(value)} truncate>
        {dateTimeService.tryFormat(value)}
      </Text>
    ) : null,
  DATE_TIME: ({ value }) =>
    value ? (
      <Text size="sm" truncate>
        {dateTimeService.tryFormatDateTime(value)}
      </Text>
    ) : null,
  TIME: ({ value }) => (typeof value === 'string' ? <Text size="sm">{value}</Text> : ''),
  CURRENCY: ({ value, field }) => (
    <Text data-system={field?.system} size="xs">
      {numberService.tryCurrency(value, field?.settings?.currency)}
    </Text>
  ),
  ACCOUNTING: ({ value, field }) => (
    <Text data-system={field?.system} size="xs">
      {numberService.formatAccounting(value as number, field?.settings?.currency)}
    </Text>
  ),
  RADIO: renderOption,
  AGE: ({ value }) => {
    return (
      <Text size="sm" title={dateTimeService.tryFormat(value)}>
        {dateTimeService.toAge(value)}
      </Text>
    );
  },
};

export const CardFieldDisplay = ({
  field,
  card,
  ...props
}: {
  field: FieldProps;
  card: Partial<Card>;
}) => {
  const value = field.preDefinedColumn
    ? card[field.system as keyof Card]
    : card.customFields?.[field.system];

  const Component = mapFieldTypeToDisplayComponent[field.type];
  if (!Component) {
    return <Text>Not implemented: {field.type}</Text>;
  }
  return <Component value={value} field={field} {...props} />;
};

export const FieldDisplayComponent = ({
  field,
  value,
  ...props
}: {
  field: FieldProps;
  value: unknown;
}) => {
  const Component = mapFieldTypeToDisplayComponent[field.type];
  if (!Component) {
    return <Text>Not implemented: {field.type}</Text>;
  }
  return <Component value={value} field={field} {...props} />;
};
