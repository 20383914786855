export const headerComponent = {
  workspace: 'סביבת עבודה',
  'Active users': 'משתמשים פעילים',
  Account: 'חברה',
  'Search {{cardType}}': 'חפש {{cardType}}',
  'Cards Type': 'סוגי כרטיסים',
  Login: 'התחבר',
  All: 'הכל',
  login: 'התחבר',
  Previous: 'הקודם',
  Next: 'הבא',
  'Back to App': 'חזרה למערכת',
  'Sign In': 'התחבר',
  'Create new organization': 'הוסף ארגון חדש',
  'No results found': 'לא נמצאו תוצאות',
  Menu: 'תפריט',
};
