'use client';
import { useAuth } from '@clerk/nextjs';
import { useMutation } from '@tanstack/react-query';
import type { PresenceMessage, RealtimeChannel, Realtime as RealtimeType } from 'ably';
import { isPlainObject } from 'is-what';
import { useParams, usePathname } from 'next/navigation';
import { useCallback, useEffect, useRef } from 'react';
import { create } from 'zustand';
import { clientConfig } from '~/core/config/config';
import { api } from '~/trpc';
import { useQueryParams } from '~/ui/hooks/app.router.hook';
import { type Audit } from '~db/types';

const isAuditMessage = (data: unknown): data is Audit => {
  if (!isPlainObject(data)) return false;
  return 'action' in data;
};

const isExternalChange = (userId: string, data: Audit) => {
  if (data.automationId) return true;
  return data.userId !== userId;
};

const isPresenceEvent = (data: unknown): data is PresenceState => {
  if (!isPlainObject(data)) return false;
  return 'event' in data;
};

const allActivities: Audit['action'][] = [
  'CREATE_EVENT',
  'UPDATE_EVENT',
  'UPDATE_TASK',
  'CREATE_TASK',
  'CREATE_COMMUNICATION',
  'UPDATE_COMMUNICATION',
  'DELETE_ACTIVITY',
];

const AUTH_URL = '/api/realtime';
type RealtimeCallback = (message: Audit) => void | Promise<void>;
type UrlState = {
  pathname: string;
  query: Record<string, string | undefined>;
  params: Record<string, string>;
};
type PresenceState = {
  urlState: UrlState;
  date: Date;
  event: 'url' | 'component';
};

export const realtimeState = create<{
  presence: Record<string, PresenceState>;
  callbacks: {
    cb: RealtimeCallback;
    actions: Audit['action'][];
  }[];
}>(() => ({
  presence: {},
  callbacks: [],
}));

export const Realtime: React.FC = () => {
  const utils = api.useUtils();
  const channel = useRef<RealtimeChannel | null>(null);
  const client = useRef<RealtimeType | null>(null);
  const auth = useAuth();
  const pathname = usePathname();
  const { params: query } = useQueryParams();
  const params = useParams();

  const updatePresence = useCallback((message: PresenceMessage) => {
    const data = message.data;
    if (!isPresenceEvent(data)) return;

    realtimeState.setState((state) => ({
      ...state,
      presence: {
        ...state.presence,
        [message.clientId]: {
          ...data,
          date: new Date(message.timestamp),
        },
      },
    }));
  }, []);

  useEffect(() => {
    if (!client.current || !channel.current) return;
    if (!channel.current.presence) return;
    if (channel.current.state !== 'attached') return;
    void channel.current.presence.update({
      event: 'url',
      urlState: {
        pathname,
        query,
        params,
      },
    } as PresenceState);
  }, [auth.orgId, auth.userId, pathname]);

  const { mutate: subscribe } = useMutation<void, Error, ReturnType<typeof useAuth>>({
    async mutationFn({ orgId, userId }) {
      if (!orgId || !userId) return;
      const ably = await import('ably');
      if (!client.current) {
        client.current = new ably.Realtime({
          authUrl: AUTH_URL,
          autoConnect: clientConfig.isClient,
          clientId: userId,
        });
      } else {
        // if the orgId changes, we need to exit the current channel
        if (channel.current) {
          await channel.current.presence.leave();
          await channel.current.detach();
        }
      }

      const main = client.current.channels.get(orgId);
      channel.current = main;
      await main.subscribe('audit', (message) => {
        const data = message.data;
        if (!isAuditMessage(data)) return;
        if (!isExternalChange(userId, data)) return;
        realtimeState.getState().callbacks.forEach((cb) => {
          if (cb.actions.length === 0 || cb.actions.includes(data.action)) {
            void cb.cb(data);
          }
        });
      });
      await main.presence.enter({ event: 'url', urlState: { pathname, query, params } });
      await main.presence.subscribe(updatePresence);
      const members = await main.presence.get();
      members.forEach(updatePresence);
    },
  });

  useEffect(() => {
    if (!auth.orgId || !auth.userId) return;
    subscribe(auth);
  }, [auth.orgId, auth.userId]);

  useEventSubscribe(allActivities, (message) => {
    const cardId = message.newData?.cardId ?? message.oldData?.cardId;
    if (cardId)
      void utils.activities.search.invalidate({
        filter: { cardId: { value: cardId } },
      });
  });

  useEventSubscribe([], (message) => {
    void utils.audit.search.invalidate();
  });

  return null;
};

export const useEventSubscribe = (actions: Audit['action'][], cb: RealtimeCallback) => {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.orgId || !auth.userId) return;
    realtimeState.setState((state) => ({
      callbacks: [...state.callbacks, { cb, actions }],
    }));

    return () => {
      realtimeState.setState((state) => ({
        callbacks: state.callbacks.filter((c) => c.cb !== cb),
      }));
    };
  }, [auth.orgId, auth.userId, cb]);
};
