import { Chip, Group, Stack, Text } from '@mantine/core';
import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { type IBaseFormInputProps } from './shared/FormInputTypes';
import { Label } from './shared/Label';
import { parseFormErrorMessage } from './shared/error-message.util';

interface FormChipsProps extends IBaseFormInputProps {
  data: { label: string; value: string | number }[];
  multiple?: boolean;
}

export const FormChips: React.FC<FormChipsProps> = ({
  name,
  label,
  required = false,
  data = [],
  multiple = true,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ref, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const stringValues = useMemo(() => value?.map((v: number | string) => v.toString()), [value]);

  const errorMessage = parseFormErrorMessage(error?.message);

  return (
    <Stack>
      {label && <Label label={label} required={required} />}
      <Group gap="xs">
        <Chip.Group multiple={multiple} value={stringValues} onChange={onChange} {...rest} {...props}>
          {data.map((chip) => (
            <Chip key={chip.value} value={chip.value.toString()}>
              {chip.label}
            </Chip>
          ))}
        </Chip.Group>
      </Group>
      {errorMessage && <Text c={'red'}>{errorMessage}</Text>}
    </Stack>
  );
};
