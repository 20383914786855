import { ClientConfigInput } from './type';

export const prodConfig: ClientConfigInput = {
  googleAds: {
    conversions: {
      lead: [
        {
          label: '5VOnCLmR-_YZENjIougq',
          value: 1.0,
          currency: 'ILS',
          gtagId: 'AW-11492959320',
        },
        {
          label: 'jjG6CIqw4PgZEJvVjd0D',
          value: 1.0,
          currency: 'ILS',
          gtagId: 'AW-1000565403',
        },
      ],
      signUp: [
        {
          label: 'sSBoCJjq-vYZENjIougq',
          value: 5.0,
          currency: 'ILS',
          gtagId: 'AW-11492959320',
        },
        {
          label: '61cKCLHR4PgZEJvVjd0D',
          value: 5.0,
          currency: 'ILS',
          gtagId: 'AW-1000565403',
        },
      ],
      organizationCreated: [
        {
          label: 'ASz_CJvq-vYZENjIougq',
          value: 10.0,
          currency: 'ILS',
          gtagId: 'AW-11492959320',
        },
        {
          label: 'f0lbCI2w4PgZEJvVjd0D',
          value: 10.0,
          currency: 'ILS',
          gtagId: 'AW-1000565403',
        },
      ],
    },
    id: {
      next: 'AW-11492959320',
      legacy: 'AW-1000565403',
    },
    enable: true,
  },
};
