import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useRepeatValues = ({ disableYearly = false } = {}) => {
  const { t } = useTranslation('dateComponent');
  const repeatTypeOptions = useMemo(() => {
    return [
      { value: 'daily', label: t('day') },
      { value: 'weekly', label: t('week') },
      { value: 'monthly', label: t('month') },
      { value: 'yearly', label: t('year'), disabled: disableYearly },
    ];
  }, [t]);

  const weekDayOptions = useMemo(
    () => [
      { value: 0, label: t('sunday') },
      { value: 1, label: t('monday') },
      { value: 2, label: t('tuesday') },
      { value: 3, label: t('wednesday') },
      { value: 4, label: t('thursday') },
      { value: 5, label: t('friday') },
      { value: 6, label: t('saturday') },
    ],
    [t],
  );

  const endsTypeOptions = useMemo(
    () => [
      { value: 'date', label: t('date') },
      { value: 'times', label: t('times') },
      { value: 'never', label: t('never'), disabled: true },
    ],
    [t, disableYearly],
  );
  return { repeatTypeOptions, weekDayOptions, endsTypeOptions };
};
