import { useMemo } from 'react';
import { useTokens } from '~/Components/Settings/Api/tokens.hook';
import { clerkClientUtils } from '~/core/auth/clerk.utils';
import { useMemberships } from './organization-memberships.hook';

type UserInfo = {
  name: string;
  imageUrl?: string;
  kind: 'USER' | 'API_TOKEN' | 'UNKNOWN';
};

export const useUserInfo = (userId: string) => {
  const memberships = useMemberships();
  const { tokens = [] } = useTokens();
  const userInfo = useMemo<UserInfo>(() => {
    const member = memberships?.data?.find((member) => member.publicUserData.userId === userId);
    if (member)
      return {
        name: clerkClientUtils.getFullName(member),
        imageUrl: member.publicUserData.imageUrl,
        kind: 'USER',
      };

    const token = tokens.find((token) => token.id === userId);
    if (token)
      return {
        name: token.name ?? token.id,
        kind: 'API_TOKEN',
      };

    return {
      name: userId,
      kind: 'API_TOKEN',
    };
  }, [tokens, memberships?.data, userId]);

  return userInfo;
};
