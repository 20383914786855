import { zodResolver } from '@hookform/resolvers/zod';
import { Accordion, ActionIcon, Button, Group, Modal, Stack, Tabs, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from '~/app/loading';
import { type ICreateCardWithRelation } from '~/server/api/schemas';
import { type PlainCardType } from '~/server/services/card-type/types';
import { cardUtils } from '~/server/services/card/card.utils';
import { appColors } from '~/ui/app-colors';
import { useAppRouter } from '~/ui/hooks/app.router.hook';
import { useAppMediaQuery } from '~/ui/hooks/media-query.hook';
import { testIdUtils } from '~/utils/test-id.service';
import { type Field } from '~db/types';
import { useCardTypes } from '../Cards/card-types.hook';
import { FormCardsSelector } from '../Form/Lookup/FormCardSelectors';
import { SubmitButton } from '../Form/Submit';
import { FormFieldsDisplay } from '../Settings/CardTypes/Fields/FieldsFormDisplay';
import { cardFieldsUtils } from '../Settings/CardTypes/Fields/fields.utils';
import { ModalFooter } from '../Shared/Modal/Footer';
import { useCreateCard } from './card.hook';

type FormValues = Omit<ICreateCardWithRelation, 'cardTypeId'>;

const CreateCardModal: React.FC<{ close: () => void }> = ({ close }) => {
  const { t } = useTranslation();
  const { t: tCard } = useTranslation('cardComponent');
  const { cardTypes, currentCardType } = useCardTypes();
  const smallScreen = useAppMediaQuery('lg');
  const [selectedCardType, setCardType] = useState<PlainCardType | null>();
  const schema = useMemo(
    () => cardFieldsUtils.createValidationSchema(selectedCardType?.fields),
    [selectedCardType?.fields],
  );
  const defaultValues = useMemo<FormValues>(
    () => ({ ...cardUtils.getDefaultValues(selectedCardType?.fields), name: '' }),
    [selectedCardType],
  );

  const formMethods = useForm<FormValues>({
    mode: 'all',
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (!cardTypes) return;
    if (currentCardType) {
      setCardType(currentCardType);
      return;
    }
    const [firstCardType] = cardTypes;
    if (firstCardType) {
      setCardType(firstCardType);
    }
  }, [cardTypes, currentCardType]);

  useEffect(() => {
    if (!selectedCardType) return;
    formMethods.reset(defaultValues);
  }, [selectedCardType, defaultValues, formMethods.reset]);

  const { createCard } = useCreateCard({
    cardType: selectedCardType,
    onCreation(card) {
      formMethods.reset(defaultValues);
      close();
    },
  });

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      if (!selectedCardType) return;
      await createCard({ ...data, cardTypeId: selectedCardType.id });
    },
    [createCard, selectedCardType],
  );

  const [importantFields, otherFields] = useMemo<[Field[], Field[]]>(() => {
    if (!selectedCardType) return [[], []];
    const {
      fieldsOrder = [],
      processFields = [],
      statusFields = [],
    } = selectedCardType.cardLayouts[0]?.settings ?? {}; // todo: handle multiple layouts
    const required = selectedCardType.fields.filter((field) => field.required);
    const important = [...processFields, ...statusFields].reduce<Field[]>((acc, system) => {
      const field = selectedCardType.fields.find((field) => field.system === system);
      if (field && !required?.includes(field)) acc.push(field);
      return acc;
    }, []);
    const ordered = fieldsOrder.reduce<Field[]>((acc, system) => {
      const field = selectedCardType.fields.find((field) => field.system === system);
      if (field && !required?.includes(field) && !important?.includes(field)) acc.push(field);
      return acc;
    }, []);
    const other = selectedCardType.fields.filter(
      (field) =>
        !ordered?.includes(field) &&
        !important?.includes(field) &&
        !required?.includes(field) &&
        !field.internal,
    );
    return [[...required, ...important, ...ordered], other];
  }, [selectedCardType]);

  if (!cardTypes) return <Loading />;
  return (
    <FormProvider {...formMethods}>
      <Modal
        p={0}
        opened={true}
        onClose={close}
        size="xl"
        title={
          <Text fw="500" size={'lg'}>
            {`${t('Create')} ${selectedCardType?.name ?? t('Record')}`}
          </Text>
        }
      >
        <Form onSubmit={() => formMethods.handleSubmit(onSubmit)()}>
          <Tabs
            keepMounted={false}
            value={selectedCardType?.id}
            onChange={(id) => setCardType(cardTypes.find((ct) => ct.id === id))}
          >
            <Tabs.List>
              <Group gap={'xs'} style={smallScreen ? { overflowX: 'auto', flexWrap: 'nowrap' } : {}}>
                {cardTypes.map((cardType) => (
                  <Tabs.Tab value={cardType.id} key={cardType.id}>
                    {cardType.pluralName}
                  </Tabs.Tab>
                ))}
              </Group>
            </Tabs.List>
            {cardTypes.map((cardType) => (
              <Tabs.Panel value={cardType.id} key={cardType.id}>
                <Stack py="lg">
                  <FormFieldsDisplay wide fields={importantFields} />
                  <Accordion>
                    <Accordion.Item value="more">
                      <Accordion.Control>{tCard('More fields')}</Accordion.Control>
                      <Accordion.Panel>
                        <FormFieldsDisplay wide fields={otherFields} />
                      </Accordion.Panel>
                    </Accordion.Item>
                    <Accordion.Item value="relations">
                      <Accordion.Control>{tCard('Relations')}</Accordion.Control>
                      <Accordion.Panel>
                        <FormCardsSelector
                          name="relatedCardIds"
                          cardTypes={cardTypes}
                          label={tCard('Related cards')}
                          description={tCard('Select cards to relate to')}
                          withinPortal
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  </Accordion>
                </Stack>
              </Tabs.Panel>
            ))}
          </Tabs>
          <ModalFooter>
            <SubmitButton {...testIdUtils.prop('create-new-record-button')}>
              {tCard('Create record')}
            </SubmitButton>
          </ModalFooter>
        </Form>
      </Modal>
    </FormProvider>
  );
};

export const CreateCardButton: React.FC = () => {
  const { t: tCard } = useTranslation('cardComponent');
  const isSmallScreen = useAppMediaQuery('lg');
  const { isLoadingCardTypes } = useCardTypes();
  const { isMarketingPage } = useAppRouter();
  const [opened, { open, close }] = useDisclosure();

  if (isMarketingPage) return null;

  return (
    <>
      {isSmallScreen ? (
        <ActionIcon {...testIdUtils.prop('new-record-button')} onClick={() => open()}>
          <IconPlus size={16} />
        </ActionIcon>
      ) : (
        <Button
          {...testIdUtils.prop('new-record-button')}
          variant="gradient"
          gradient={{ from: appColors.themeColor, to: 'grape', deg: 60 }}
          rightSection={<IconPlus size={16} />}
          onClick={() => open()}
          disabled={isLoadingCardTypes}
        >
          {tCard('New record')}
        </Button>
      )}
      {opened ? <CreateCardModal close={close} /> : null}
    </>
  );
};
